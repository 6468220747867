import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "bg"
};
const _hoisted_2 = {
  class: "index-detailed"
};
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Tabbar = _resolveComponent("Tabbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    src: $data.imgList[$data.index],
    alt: ""
  }, null, 8, _hoisted_3)]), _createVNode(_component_Tabbar)]);
}