export default {
  data() {
    return {
      imgList: [require("../../assets/index/item_1.jpg"), require("../../assets/index/item_2.jpg"), require("../../assets/index/item_3.jpg")],
      index: 0
    };
  },
  created() {
    this.index = this.$route.query.id;
  }
};